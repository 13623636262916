import Editor from './Editor';
import { useRef, useState } from 'react';
import { useEffect } from 'react';
import Playlist from './Playlist';
import axios from "axios";
import { trackPromise } from 'react-promise-tracker';
import { memo } from 'react';
import { Base64 } from 'js-base64';
//import svg from '../shared/rectangle.svg'


function Playlists(props) {
    const [focusedPlaylist, setFocusedPlaylist] = useState(null)
    const [playlists, setPlaylists] = useState([])
    const [initSetup, setInitSetup] = useState(0)

    let isMounted = useRef(false)
    let { userData } = props

    let savedTracks = JSON.parse(localStorage.getItem('savedTracks'))
    let allGenres = JSON.parse(localStorage.getItem('allGenres'))
    const baseUrl = process.env.REACT_APP_API_URL

    // general idea, onload the playlists are loaded, as they change, they are updated in the db
    // but remain local

    function deletePlaylist(playlist) {
        let temp = playlists.filter(tempPlaylist => tempPlaylist.id !== playlist.id && tempPlaylist.id !== null)
        setFocusedPlaylist(null)
        setPlaylists(temp.sort((a, b) => b.lastEdited - a.lastEdited))
        delete playlist.spotifyPlaylistObject

        axios.delete(`https://api.spotify.com/v1/playlists/${playlist.id}/followers`)
        axios.post(`${baseUrl}/deleteCustomPlaylist`, {
            id: Base64.encode(userData.id),
            payload: JSON.stringify(playlist)
        })
    }

    const sortGenres = async function (savedTracks) {
        let allArtists = new Set()
        let ArtistArray = new Set() 
        const start = performance.now();
        savedTracks.forEach(({ track }) => {
            for (let index = 0; index < track.artists.length; index++) {
                allArtists.add(track.artists[index].id)
                ArtistArray.add(track.artists[index].name)
            }
        })

        let query = ''
        async function getGenres(artists) {
            if (artists.length === 0) {
                return []
            }

            setInitSetup(initSetup => initSetup + (100 / (allArtists.length / 50)))

            query = artists.splice(0, Math.min(50, artists.length)).join()
            let { data } = await axios.get('https://api.spotify.com/v1/artists?ids=' + query)
            return [...data.artists, ...await getGenres(artists)]
        }

        allArtists = [...allArtists]
        ArtistArray = [...ArtistArray]
        localStorage.setItem('ArtistArray', JSON.stringify(ArtistArray.sort()))

        let chunkArray = []
        let chunkSize = Math.ceil((allArtists.length / 50) / 6) * 50

        for (let index = 0; index < 6; index++) {
            chunkArray.push(getGenres(allArtists.slice(chunkSize * index, (index + 1) * chunkSize)))
        }

        await Promise.all(chunkArray).then((temp) => {
            temp = temp.flat()
            allGenres = {}
            temp = temp.map(({ genres, id, name }) => {
                genres.forEach((genre) => {
                    allGenres[genre] = [...allGenres[genre] || [], name]
                })
                return { genres, id, name }
            })

            localStorage.setItem('GenreArray', JSON.stringify(Object.keys(allGenres).sort()))
            localStorage.setItem('allGenres', JSON.stringify(allGenres))
            const end = performance.now();
            console.log(`Genre sort Execution time: ${end - start} ms`);
        })
        return
    }


    const updateSavedTracks = async function () {
        async function getSavedTracks(next, myMax = -1) {
            if (next === undefined) {
                next = 'https://api.spotify.com/v1/me/tracks?offset=0&limit=1'
            }
            if (next !== null && myMax !== 0) {
                let { data } = await axios.get(next)
                let { total, items } = data

                if (myMax === -1) {
                    let chunkArray = []
                    let myMax = Math.ceil((total / 50) / 6)
                    for (let index = 0; index < 6; index++) {
                        let url = `https://api.spotify.com/v1/me/tracks?offset=${index * (myMax * 50)}&limit=50`
                        chunkArray.push(getSavedTracks(url, myMax))
                    }
                    return await Promise.all(chunkArray).then(arrays => arrays.flat())
                }
                setInitSetup(initSetup => initSetup + (100 / (total / 50)))

                //removes an unnecessary array
                items.forEach(({ track }) => {
                    delete track.available_markets
                    delete track.album?.available_markets
                })


                return [...items, ...await getSavedTracks(data.next, --myMax)]
            } else {
                return []
            }
        }
        if (!savedTracks) {
            const start = performance.now();
            savedTracks = await getSavedTracks()
            const end = performance.now();
            console.log(`getSaved tracks Execution time: ${end - start} ms`);
            localStorage.setItem('savedTracks', JSON.stringify(savedTracks))
            await sortGenres(savedTracks)
        } else {
            let { data } = await axios.get('https://api.spotify.com/v1/me/tracks?offset=0&limit=1')

            let redo = data.items.some((item, index) => {
                return item.added_at !== savedTracks[index]?.added_at
            })

            if (redo || savedTracks.length !== data.total) {
                savedTracks = await getSavedTracks()
                localStorage.setItem('savedTracks', JSON.stringify(savedTracks))
                await sortGenres(savedTracks)
            }
        }
        setInitSetup(0)
        return savedTracks
    }

    useEffect(() => {
        // on initial load
        const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

        if (!isMounted.current) {
            updateSavedTracks()
            trackPromise(axios.post(`${baseUrl}/getCustomPlaylists`, { id: Base64.encode(userData.id) }).then(({ data }) => {
                data = data.map((playlist) => {
                    if(base64regex.test(playlist.title)) {
                        playlist.title = Base64.decode(playlist.title)
                    }
                    playlist.content = playlist.content.map((item) => {
                        if(item.isEncoded){
                            item.value = Base64.decode(item.value)
                        }
                        return item
                    })
                    return playlist
                })
                data.sort((a, b) => b.lastEdited - a.lastEdited)
                setPlaylists(data)
            }))
            isMounted.current = true
        }
    })

    function editPlaylist(playlist) {
        setPlaylists(playlists =>
            playlists.map((oldPlaylist) => {
                if (playlist.id === oldPlaylist.id || oldPlaylist.id === null) {
                    return playlist
                }
                return oldPlaylist
            }).sort((a, b) => b.lastEdited - a.lastEdited)
        )
    }

    return (
        <>
            <button
                className="grn-btn"
                disabled={focusedPlaylist || playlists.length >= 10}
                onClick={() => {
                    if (playlists.length < 10) {
                        let newPlaylist = { id: null, dateCreated: Date.now() }
                        setFocusedPlaylist(newPlaylist)
                        setPlaylists(playlists => [newPlaylist, ...playlists])
                    }
                }}
            >
                {initSetup > 0 ? `Loading: ${Math.min(Math.floor(initSetup / 2), 100)}%` : 'New Playlist'}
            </button>
            <div className="playlist-container">
                {playlists.map((playlist) => {
                    return <Playlist
                        key={playlist.id}
                        playlist={playlist}
                        edit={(playlist) => {
                            setFocusedPlaylist(playlist)
                            updateSavedTracks()
                        }}
                    />
                })
                }
            </div>
            {focusedPlaylist &&
                <Editor
                    playlist={focusedPlaylist}
                    delete={deletePlaylist}
                    editPlaylist={editPlaylist}
                    close={setFocusedPlaylist}
                    userData={userData}
                />
            }
        </>
    )
}

export default memo(Playlists)