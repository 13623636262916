import Panel from "./Panel";
import Input from './Input';
import PlaylistContent from './PlaylistContent';
import { useEffect, useRef, useState, useCallback } from 'react';
import axios from "axios";
import { trackPromise } from 'react-promise-tracker';
import { intersect, difference, calculate } from '../shared/functions/playlistManipulation'
import getTracks from './../shared/functions/getTracks';
import { Base64 } from 'js-base64';



export default function Editor(props) {
    const { userData, playlist, close, editPlaylist } = props || {}
    const [queryType, setQueryType] = useState('')
    const [content, setContent] = useState(playlist?.content || [])
    const [title, setTitle] = useState(playlist?.title || 'Untitled')
    const [spotifyPlaylistObject, setSpotifyPlaylistObject] = useState(null)
    //const [id, setId] = useState(playlist.id || null)

    const initialTracks = useRef([])
    const removalQueue = useRef([])
    const isMounted = useRef(false)
    const isEdited = useRef(false)
    const snapshot = useRef(null)
    const playlistID = useRef(playlist.id || null)

    const baseUrl = process.env.REACT_APP_API_URL

    //when title changes, update
    // when content added/deleted, update
    // on update, update list in parent

    let buttonTypes = [
        { type: 'Artist' },
        { type: 'Genre' }
    ]

    let getPlaylist = useCallback(function () {
        //the db is the source of truth
        waitOnVal(playlistID)
        if(JSON.stringify( playlist.content) !== JSON.stringify(content))
            isEdited.current = true

        return {
            id: playlistID.current, //static
            content, //dynamic - handled on backend 
            title, // dynamic 
            spotifyPlaylistObject,
            dateCreated: playlist.dateCreated || Date.now(), //static
            lastEdited: isEdited.current ? Date.now() : (playlist.lastEdited || Date.now()) //dynamic 
        }
    }, [title, content, playlist, spotifyPlaylistObject])

    useEffect(() => {
        if(!spotifyPlaylistObject || !playlistID.current){
            return
        }

        if (spotifyPlaylistObject && title !== spotifyPlaylistObject.name && playlistID.current) {
            console.log("updating title")
            let payloadForSpotify = {
                public: false,
                name: title
            }
            axios.put(`https://api.spotify.com/v1/playlists/${playlistID.current}`, payloadForSpotify)

            isEdited.current = true
            let payload = getPlaylist()
            delete payload.spotifyPlaylistObject
            delete payload.content
            payload.title = Base64.encode(payload.title)
            
            trackPromise(axios.post(`${baseUrl}/updateCustomPlaylist`, { 
                id: Base64.encode(userData.id), 
                payload: JSON.stringify(payload)
            }))
        }
    }, [getPlaylist, userData.id, baseUrl, spotifyPlaylistObject, title])

    useEffect(() => {
        // onmount function
        // creates a spotify playlist if it doesn't exist

        if(isMounted.current){
            return
        }

        async function createSpotifyPlaylist() {
            let payload = {
                public: false,
                name: title,
                description: 'find out more at https://otw.mikalyoung.com'
            }
            let { data } = await axios.post(`https://api.spotify.com/v1/users/${userData.id}/playlists`, payload)
            return data
        }
        async function synchronize (data) {
            //setId(data.id)
            playlistID.current = data.id
            snapshot.current = data.snapshot_id
            if (data.tracks.total > 100){
                trackPromise(getPlaylistTracks(data.id).then((tracks) => {
                    initialTracks.current.push(tracks)
                }))
            } else if(data.tracks){
                initialTracks.current.push(data.tracks.items.map(({ track }) => track.uri))
            }
            //now you can delete the tracks
            delete data.tracks
            setSpotifyPlaylistObject(data)
        }

        if (playlistID.current === null) {
            trackPromise(
                createSpotifyPlaylist().then((newPlaylist) => {
                    let payload = getPlaylist()
                    delete payload.spotifyPlaylistObject
                    payload.id = newPlaylist.id
                    trackPromise(axios.post(`${baseUrl}/createCustomPlaylist`, { id: Base64.encode(userData.id), payload: JSON.stringify(payload) })
                    .then(() => {
                        if(baseUrl){
                            console.log(newPlaylist)
                        }
                        synchronize(newPlaylist)
                    }))
                })
            )
        } else if (playlistID.current) {
            // check if playlist exists, if true keep new object for comparison
            trackPromise(
                axios.get(`https://api.spotify.com/v1/playlists/${playlistID.current}?fields=id,uri,name,external_urls,snapshot_id,tracks`)
                    .then(({ data }) => {
                        if (!data) {
                            console.log('creating playlist')
                            createSpotifyPlaylist()
                        } else {
                            synchronize(data)
                        }
                    })
            )
            if (content[0] && !content[0].id) {
                let temp = content.map((item) => {
                    item.id = Date.now() + Math.round(Math.random() * 1000)
                    return item
                })
                setContent(temp)
            }
        }
        isMounted.current = true
    }, [title, userData, content, getPlaylist, baseUrl, playlist])

    async function getPlaylistTracks(playlistID) {
        let playlistTracks = await getTracks(playlistID)
        playlistTracks = playlistTracks.map(({ track }) => track.uri)
        return playlistTracks
    }

    const getURIs = useCallback(async function  (id) {
        let urisInPlaylist = initialTracks.current[initialTracks.current.length - 1]
        if(!urisInPlaylist){
            urisInPlaylist = await new Promise((resolve, reject) => {
                let count = 0
                let tempInterval = setInterval(() => {
                    if(initialTracks.current[initialTracks.current.length - 1]) {
                        clearInterval(tempInterval)
                        resolve(initialTracks.current[initialTracks.current.length - 1])
                    } else if(count === 5){
                        clearInterval(tempInterval)
                        getPlaylistTracks(id).then((tracks) => {
                            resolve(tracks || [])
                        })
                    }
                    count++
                }, 1000)
            })
        }
        return urisInPlaylist
    },[initialTracks])

    async function waitOnVal(val){
        return new Promise((resolve, reject) => {
            let count = 0
            let interval = setInterval(() => {
                if (val.current){
                    clearInterval(interval)
                    resolve(val)
                } else if( count === 30){
                    reject(val)
                }
                count++
            }, 500)
        })
    }

    const addContent = useCallback(async function ({ target, oldContent }) {
        //dependencies: id, initialTracks
        oldContent = oldContent || [...content]
 

        await waitOnVal(playlistID)
        let trueContentToAdd = [];
        if(Array.isArray(target)){
            trueContentToAdd= calculate(target)
            console.log(target)
        } else {
            if(oldContent.some((existingTarget)=>{
                return target.type === existingTarget.type && target.value === existingTarget.value
            })){
                return
            }
            trueContentToAdd= calculate([target])
            setContent(content => [...content, target])
        }

         
        let urisInPlaylist = await getURIs(playlistID.current)
        let contentToAdd = difference(trueContentToAdd, urisInPlaylist)

        initialTracks.current.push([...urisInPlaylist, ...contentToAdd])

        let clone = {
            ...target
        }
        
        clone.value = Base64.encode(target.value)
        clone.isEncoded = true
    
        let payload = {
            playlistID: playlistID.current,
            contentToAdd: JSON.stringify(contentToAdd),
            context: JSON.stringify({target: clone, userID: Base64.encode(userData.id)})
        }
        if(baseUrl){
            console.log(contentToAdd)
        }
        if(Array.isArray(target)){
            // the new content has been added
            //return;
        }

        return await axios.post(`${baseUrl}/addContent`, payload)
            .then(async ({ data }) => {
                if (data.snapshot_id) {
                    snapshot.current = data.snapshot_id
                }
            }).catch(async (error) => {
                console.log(error)
                let response = error.response
                console.log(response)
                initialTracks.current = []
                initialTracks.current.push(await getPlaylistTracks(playlistID.current))
            })
    }, [content, baseUrl, userData, getURIs])

    const removeContent = useCallback(async function ({ target, oldContent }) {
        // confusing, ik
        // if old content is null, then this is the first run

        let contentArray = oldContent || [...content]
        oldContent = [...contentArray]
        
        contentArray = contentArray.filter((item) => {
            return target.id !== item.id
        })

        setContent(contentArray)

        if(removalQueue.current.length === 0){
            // the initial job
            let lastLength = 0
            removalQueue.current.push(target)
            await new Promise((resolve, reject) => {
                let tempInterval = setInterval(() => {
                    if (removalQueue.current.length === lastLength && initialTracks.current[initialTracks.current.length - 1] ) {
                        clearInterval(tempInterval)
                        resolve()
                        console.log(JSON.stringify(removalQueue.current))
                    }
                    lastLength = removalQueue.current.length
                }, 750)
            })
        } else {
            removalQueue.current.push(target)
            return
        }

        contentArray = oldContent.filter((item) => {
            return !removalQueue.current.some((job) => {
                return job.id === item.id
            })
        })

        let contentToRemove = removalQueue.current || []
        removalQueue.current = []

        let recalculated = calculate(contentArray)
        let targetContent = calculate(contentToRemove)
        await waitOnVal(playlistID)
        
        //array of targets or array of 1,
        //depends on if its in the queue

        // if the track ends up in the set below, no other content item claims it
        let URIsToRemove = difference(targetContent, recalculated)
        // detect duplicates
        

        // we can only remove whats there already
        let urisInPlaylist = await getURIs(playlistID.current)
        URIsToRemove = intersect(URIsToRemove, urisInPlaylist)
        //console.log(URIsToRemove)

        contentToRemove = contentToRemove.map((item) => {
            if(item.isEncoded){
                item.value = Base64.encode(item.value)
            }
            return item
        })


        let payload = {
            playlistID: playlistID.current,
            contentToRemove: JSON.stringify(URIsToRemove),
            snapshot_id: snapshot.current,
            context: JSON.stringify({target: contentToRemove, userID: Base64.encode(userData.id)})
        }
        initialTracks.current = []
        initialTracks.current.push(difference(urisInPlaylist, URIsToRemove))
        if(baseUrl){
            console.log(URIsToRemove)
        }

        axios.post(`${baseUrl}/removeContent`, payload)
            .then(async ({ data }) => {
                if (data.snapshot_id) {
                    snapshot.current = data.snapshot_id
                }
            }).catch(async (error) => {
                console.log(error)
                let response = error.response
                if(response.status <= 500){
                    console.log('error in removing')
                }
                initialTracks.current = []
                initialTracks.current.push(await getPlaylistTracks(playlistID.current))
            })

    }, [ content, baseUrl, userData, getURIs])

    buttonTypes = buttonTypes.map(({ type }) => {
        return (
            <button
                key={type}
                className="grn-btn"
                onClick={() => {
                    setQueryType(type)
                }}
            >
                Add {type}
            </button>
        )
    })

    if (playlist) {
        return (
            <div key={spotifyPlaylistObject?.id || 1} className="editor window shadow">
                <Panel
                    setTitle={queryType ? null : setTitle}
                    title={title}
                    header={title}
                    close={() => {
                        if (title.trim() !== '') {
                            close(null)
                            editPlaylist(getPlaylist())
                        }
                    }}
                />
                <div className="control-panel">
                    {buttonTypes}
                    <button
                        className={'grn-btn'}
                        onClick={() => {
                            isEdited.current = true
                            addContent({target: content}).then(() => {
                                window.open(spotifyPlaylistObject.external_urls.spotify, '_blank')
                            });
                           
                        }}
                    >Resync</button>
                    <div>
                        <button
                            className="red-btn"
                            onClick={() => props.delete(getPlaylist())}
                        >Delete</button>
                    </div>      
                </div>
                <PlaylistContent
                    key={content.length}
                    content={content}
                    removeContent={removeContent}
                />
                {queryType &&
                    <Input
                        type={queryType}
                        close={() => setQueryType(null)}
                        addContent={addContent}
                    />
                }
            </div>
        )
    } else
        return
}
